@import "../../colours.scss";

.failed-container {
    text-align: center;
    .icon {
        svg {
            font-size: 80px;
            color: $negative-colour;
        }
    }
    .icon.warning {
        svg {
            color: orangered;
        }
    }
    .button-container {
        width: 100px;
        margin: auto;
        .button {
            width: 100%;
        }
    }
}