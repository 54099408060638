@import "../../colours.scss";

.profile {
    .profile-pic {
        position: relative;
        height: 300px;
        margin-bottom: 20px;
        cursor: pointer;
        overflow: hidden;
        img {
            height: 100%;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
        }
        .change {
            display: none;
            height: 100%;
            width: 100%;
            position: absolute;
            opacity: 0.5;
            .overlay {
                width: 100%;
                height: 100%;
                background: $second-colour;
            }
            span {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                color: white;
            }
        }
        @media only screen and (max-width: 500px) {
            height: 200px;
        }
    }
    .profile-pic:hover {
        .change {
            display: flex;
        }
    }
    .change-password {
        margin-bottom: 50px;
    }
}