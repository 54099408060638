@import "../../colours.scss";

.sponsors {
    .sponsor {
        text-align: center;
        margin-bottom: 30px;
        border-bottom: 0.5px solid grey;
        padding-bottom: 30px;
        .name {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 10px;
        }
        .pic {
            height: 200px;
            margin-bottom: 20px;
            img {
                height: 100%;
            }
        }
        .title {
            color: $first-colour;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
        .website {
            margin-top: 10px;
            span {
                margin-left: 5px;
                text-decoration: underline;
            }
        }
    }
    h2 {
        text-align: center;
    }
}
