@import "../../colours.scss";

.dashboard {
    .options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .option {
            margin: 5px;
            height: 300px;
            width: calc((100% / 4) - 11px);
            -webkit-box-shadow: 10px 6px 21px -7px rgba(0,0,0,0.75);
            -moz-box-shadow: 10px 6px 21px -7px rgba(0,0,0,0.75);
            box-shadow: 10px 6px 21px -7px rgba(0,0,0,0.75);
            animation: fadeIn 1.0s ease forwards;
            background: $second-colour;
            border: $first-colour 0.5px solid;
            cursor: pointer;
            .icon {
                height: 50%;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    font-size: 70px;
                }
            }
            .label {
                display: flex;
                height: calc(50% - 10px);
                padding: 5px;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 25px;
                color: white;
                text-align: center;
            }
            @media only screen and (max-width: 1300px) {
                width: calc((100% / 3) - 11px);
                .label {
                    font-size: 20px;
                }
            }
            @media only screen and (max-width: 800px) {
                margin: 2px;
                height: 220px;
                width: calc((100% / 2) - 5px);
            }
        }
        .option:hover {
            .icon {
                color: $first-colour;
            }
            .label {
                color: $first-colour;
            }
        }
    }
}