@import "../../colours.scss";

.find-mentees {
    .filters {
        margin-bottom: 20px;
        .filter-container {
            display: block;
            position: relative;
            margin: auto;
            max-width: 600px;
            width: 100%;
        }
        .row {
            height: 60px;
            position: relative;
            margin-bottom: 10px;
            .extra-label {
                margin-bottom: 0;
                text-align: left;
            }
        }
        .row.radio {
            height: auto !important;
        }
        .row.multi {

            margin-top: 30px;
            height: auto !important;
        }
        .buttons {
            margin: auto;
            height: auto !important;
            margin-top: 40px;
            .button-container {
                margin-bottom: 20px;
            }
            .button {
                margin: auto;
                left: 0;
                right: 0;
                position: absolute;
                width: 150px;
            }
        }
    }
    .pages {
        text-align: center;
        display: flex;
        background: white;
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
        .page-info {
            flex: 2;
            height: 50px;
            justify-content: center;
            align-items: center;
            display: flex;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome and Opera */
        }
        .arrow {
            flex: 1;
            position: relative;
            cursor: pointer;
            height: 50px;
            margin: auto;
            svg {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                right: 0;
                font-size: 40px;
            }
        }
        .arrow:hover {
            opacity: 0.6;
            background: lightgrey;
        }
    }
    .mentees-container {
        position: relative;
        .items {
            width: 100%;
            margin-top: 20px;
            font-size: 16px;
            color: $font-colour;
            .paper-inner {
                .star {
                    position: absolute;
                    top: 0;
                    left: 0;
                    svg {
                        font-size: 60px;
                        color: $first-colour;
                    }
                }
                .star.hide {
                    display: none;
                }
                .title-details {
                    .name {
                        margin-bottom: 20px;
                        text-align: center;
                        font-size: 18px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    .pic {
                        position: relative;
                        height: 370px;
                        overflow: hidden;
                        img {
                            height: 100%;
                            margin: auto;
                            left: 0;
                            right: 0;
                            position: absolute;
                        }
                        @media only screen and (max-width: 500px) {
                            height: 200px;
                        }
                    }
                }
                .all-details {
                    margin-top: 20px;
                    .row {
                        display: flex;
                        margin-bottom: 10px;
                        .col {
                            flex: 1;
                            text-align: left;
                            padding-left: 5px; 
                        }
                        .title {
                            text-align: right;
                            padding-right: 5px; 
                            font-weight: bold;
                        }
                        p {
                            display: block;
                            text-align: center !important;
                        }
                    }
                    .no-flex {
                        display: block;
                    }
                }
                .buttons {
                    height: auto !important;
                    margin-top: 20px;
                    .button-container {
                        margin-bottom: 20px;
                        .button {
                            width: 100%;
                            max-width: 300px;
                            margin: auto;
                            position: absolute;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}