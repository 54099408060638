@import "../../colours.scss"; 

.auto-complete-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    div:nth-child(2) {
        position: absolute;
        width: 100%;
        z-index: 999;
    }
    textarea {
        opacity: 1;
    }
    div {
        div {
            flex-wrap: wrap;
        }
        div::after {
            border-bottom: $first-colour 2px solid;
        }
    }
    .auto-complete-input {
        position: relative;
        margin-top: 10px;
    }

    .auto-complete-paper {
        div {
            position: relative;
        }
    }
}

.auto-complete-container.error {
    .auto-complete-input {
        input {
            color: red;
        }
        div::after {
            border-bottom: red 2px solid;
        }
    }
}