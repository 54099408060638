@import "../../colours.scss";

.confirm-mentoring-session {
    padding-bottom: 50px;
    .add-timeslot {
        display: flex;
        cursor: pointer;
        svg {
            position: absolute;
            top: 0;
            bottom: 0;
            display: inline-block;
            margin: auto;
        }
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
        }
    }
    .add-timeslot:hover {
        opacity: 0.6;
    }
    .row.custom {
        margin-top: 10px;
        .input-field-container {
            width: auto;
            float: left;
            .input-field {
                width: auto;
            }
        }
    }
    .comments {
        margin-bottom: 40px;
        color: $font-colour-second;
        span {
            font-weight: bold;
        }
    }
    .row {
        .close {
            margin: auto;
            top: 0;
            border: 0;
            height: 100%;
            padding-left: 10px;
            font-size: 28px;
            cursor: pointer;
        }
    }

}