@import "../../colours.scss";

.applications {
    .none {
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
    }
    .items {
        margin-top: 20px;
        font-size: 16px;
        color: $font-colour;
        .pages {
            text-align: center;
            display: flex;
            margin-bottom: 20px;
            .page-info {
                flex: 2;
                height: 50px;
                justify-content: center;
                align-items: center;
                display: flex;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                 -khtml-user-select: none; /* Konqueror HTML */
                   -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome and Opera */
            }
            .arrow {
                flex: 1;
                position: relative;
                cursor: pointer;
                height: 50px;
                margin: auto;
                svg {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    left: 0;
                    right: 0;
                    font-size: 40px;
                }
            }
            .arrow:hover {
                opacity: 0.6;
                background: lightgrey;
            }
        }
        .paper-inner {
            .title-details {
                .name {
                    margin-bottom: 20px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                .pic {
                    position: relative;
                    height: 300px;
                    overflow: hidden;
                    img {
                        height: 100%;
                        margin: auto;
                        left: 0;
                        right: 0;
                        position: absolute;
                    }
                    @media only screen and (max-width: 500px) {
                        height: 200px;
                    }
                }
            }
            .all-details {
                margin-top: 20px;
                .row {
                    display: flex;
                    margin-bottom: 10px;
                    .col {
                        flex: 1;
                        text-align: left;
                        padding-left: 5px; 
                    }
                    .title {
                        text-align: right;
                        padding-right: 5px; 
                        font-weight: bold;
                    }
                    p {
                        display: block;
                        text-align: center !important;
                    }
                }
                .no-flex {
                    display: block;
                }
            }
            .buttons {
                margin-top: 30px;
                .button-container {
                    margin-bottom: 30px;
                    .button {
                        width: 100%;
                        max-width: 300px;
                        margin: auto;
                        position: absolute;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}