//PANTONE 16-1546 Living Coral

//OPTION 1
$first-colour: #FF6F61;
$second-colour: #02111B;
$third-colour: #FCFCFC;
$fourth-colour: #FCFCFC;
$font-colour: #50514F;
$font-colour-second: #456990;
$negative-colour: #DF3B57;

//OPTION 2
// $first-colour: #FF6F61;
// $second-colour: #14213D;
// $third-colour: #F6E8EA;
// $fourth-colour: #F6E8EA;
// $font-colour: #22181C;
// $font-colour-second: #456990;

//OPTION 3
// $first-colour: #FF6F61;
// $second-colour: #2F131E;
// $third-colour: #F6E8EA;
// $fourth-colour: #F6E8EA;
// $font-colour: #22181C;
// $font-colour-second: #456990;

//OPTION 4
// $first-colour: #FF6F61;
// $second-colour: #5E4352;
// $third-colour: #F6E8EA;
// $fourth-colour: #F6E8EA;
// $font-colour: #50514F;
// $font-colour-second: #456990;