@import "../../colours.scss"; 

.downshift-multiple-container {
    position: relative;
    margin-top: 10px;
    textarea {
        opacity: 1;
    }
    div {
        div {
            flex-wrap: wrap;
        }
        div::after {
            border-bottom: $first-colour 2px solid;
        }
    }
    .keyword-input {
        position: relative;
        margin-top: 10px;
    }
    .keyword-input.error {
        textarea {
            color: red;
        }
        div::after {
            border-bottom: red 2px solid;
        }
    }
    .chip {
        margin-bottom: 15px;
    }
    .highlighted {
        color: $font-colour-second;
    }
    .selected {
        color: $first-colour;
    }
}