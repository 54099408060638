@import "../../colours.scss"; 

.input-field-container {
    .input-field {
        width: 100%;
        label {
            color: black;
        }
        div::after {
            border-bottom: $first-colour 2px solid;
        }
        input,textarea {
            color: $font-colour-second;
        }
    }
    .input-field.error {
        label {
            color: red;
        }
        div::after {
            border-bottom: red 2px solid;
        }
    }
}