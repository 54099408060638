@import "../../colours.scss";

.button-container {
    position: relative;
    height: 36px;
    .button {
        background-color: $first-colour;
        .spinner-container {
            height: 36px;
            position: absolute;
            right: 0;
            .spinner {
                width: 25px !important;
                height: 25px !important;
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
                right: 8px;
                svg {
                    color: $first-colour;
                }
            }
        }
    }
    .button:hover {
        background-color: $first-colour;
        opacity: 0.7;
    }
    .button.second {
        background-color: $font-colour-second;
        color: white;
    }
    .button.second:hover {
        background-color: $font-colour-second;
        opacity: 0.7;
    }
    .button.disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        box-shadow: none !important;
        background-color: rgba(0, 0, 0, 0.12) !important;
    }
}