@import "../../colours.scss";

.distractor-container {
    height: 400px;
    .distractor {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        svg {
            color: $first-colour;
        }
    }
}