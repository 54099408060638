@import "../../colours.scss";

.page-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    svg {
        color: $first-colour;
    }
}