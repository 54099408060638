@import "../../colours.scss";

.world-map {
    position: relative;
    margin-top: 50px;
    .title {
        text-align: center;
        width: 100%;
        height: 60px;
        font-weight: bold;
        font-size: 20px;
        color: $font-colour;
    }
    .filter-row {
        width: 245px;
        margin: auto;
        margin-bottom: 30px;
        text-align: center;
    }
}
