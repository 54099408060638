@import "../../colours.scss";

.testo {

    width: calc(100% - 40px) !important;
    padding: 20px;
    text-align: center;

    .image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        justify-content: center;
        max-width: 340px;
        width: 100%;
        img {
            width: 100%;
        }
    }
    
    .quotes {
        position: absolute;
    }
    
    .text {
        display: flex;
        justify-content: center;
        font-size: 25px;
        font-style: italic;
        position: relative;
        padding: 16px;
        max-width: 600px;
        width: 100%;
        margin: auto;
        margin-top: 20px;
        .quotes {
            position: absolute;
            left: -9px;
            top: -2px;
            svg {
                font-size: 35px;
                @media only screen and (max-width: 1000px) {
                    font-size: 25px;
                }
                @media only screen and (max-width: 600px) {
                    font-size: 20px;
                }
            }
        }
        @media only screen and (max-width: 1000px) {
            padding: 10px;
            font-size: 20px;
        }
        @media only screen and (max-width: 600px) {
            font-size: 16px;
        }
    }
    
    .name {
        color: $first-colour;
        position: relative;
        font-style: bold;
        font-size: 18px;
    }
    
    .details {
        position: relative;
        font-size: 16px;
        color: $first-colour;
    }
}