@import "../../colours.scss";

.find-mentors {
    .filters {
        margin-bottom: 20px;
        .filter-container {
            display: block;
            position: relative;
            margin: auto;
            max-width: 600px;
            width: 100%;
        }
        .row {
            height: 60px;
            position: relative;
            margin-bottom: 10px;
            .extra-label {
                margin-bottom: 0;
                text-align: left;
            }
        }
        .row.radio {
            height: auto !important;
        }
        .row.multi {

            margin-top: 30px;
            height: auto !important;
        }
        .buttons {
            margin: auto;
            height: auto !important;
            margin-top: 40px;
            .button-container {
                margin-bottom: 20px;
            }
            .button {
                margin: auto;
                left: 0;
                right: 0;
                position: absolute;
                width: 150px;
            }
        }
    }
    .mentors-container {
        position: relative;

        .items {
            display: flex;
            flex-wrap: wrap;
            .mentor {
                flex: 1 0 21%;
                margin-top: 20px;
                font-size: 16px;
                color: $font-colour;
                margin: 10px;
                padding: 20px;
                border: 1px solid black;
                cursor: pointer;
                .title-details {
                    .name {
                        margin-bottom: 20px;
                        text-align: center;
                        font-size: 18px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    .pic {
                        position: relative;
                        height: 370px;
                        overflow: hidden;
                        img {
                            height: 100%;
                            margin: auto;
                            left: 0;
                            right: 0;
                            position: absolute;
                        }
                        @media only screen and (max-width: 500px) {
                            height: 200px;
                        }
                    }
                }
                .all-details {
                    margin-top: 20px;
                    .row {
                        display: flex;
                        margin-bottom: 10px;
                        .col {
                            flex: 1;
                            text-align: left;
                            padding-left: 5px; 
                        }
                        .title {
                            text-align: right;
                            padding-right: 5px; 
                            font-weight: bold;
                        }
                        p {
                            display: block;
                            text-align: center !important;
                        }
                    }
                    .no-flex {
                        display: block;
                    }
                }
            }
            .mentor:hover {
                background: lightgrey;
            }
        }

    }
}