@import "../../colours.scss";

.menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    transition-duration: 0.4s;
    .header {
        position: relative;
        top: 0;
        width: 100vw;
        height: 140px;
        background: $second-colour;
        .text {
            color: $third-colour;
            top: 20px;
            position: absolute;
            width: 90%;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            cursor: pointer;
            .site-name {
                font-size: 56px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 10px;
                margin-top: 0;
                margin-bottom: 0;
                font-family: Lato, sans-serif,'helvetica neue',helvetica,arial,verdana;
            }
            .slogan {
                font-size: 25px;
                color: $first-colour;
            }
        }
    }
    .burger {
        position: absolute;
        top: 53px;
        left: 25px;
        width: 35px;
        cursor: pointer;
        .line {
            background: $third-colour;
            width: 100%;
            height: 2px;
            margin-bottom: 10px; 
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: black;
        opacity: 0.3;
        left: -100%;
        transition-duration: 0.4s;
    }
    .overlay.show {
        left: 0px;
    }
    .menu-options {
        width: 400px;
        top: 0;
        left: -441px;
        height: 100vh;
        position: absolute;
        background: $second-colour;
        border-right: $first-colour 0.5px solid;
        transition-duration: 0.6s;
        padding-left: 20px;
        padding-right: 20px;
        overflow-y: scroll;
        
        .back-button {
            text-align: center;
            color: $third-colour;
            position: relative;
            top: 20px;
            width: 100%;
            height: 60px;
            svg {
                position: absolute;
                right: 0;
                font-size: 40px;
                cursor: pointer;
            }
            svg:hover {
                opacity: 0.6;
            }
        }
        .option {
            width: 100%;
            height: 90px;
            color: $third-colour;
            display: flex;
            align-items: center;
            border-bottom: 0.5px solid $first-colour;
            font-size: 20px;
            text-transform: uppercase;
            cursor: pointer;
            .icon {
                display: flex;
                justify-content: center;
                flex: 1;
                svg {
                    font-size: 30px;
                }
            }
            .label {
                display: flex;
                flex: 3;
            }
        }
        .option:hover {
            opacity: 0.6;
            //transform: scale(1.1);
            .icon {
                svg {
                    color: $first-colour;
                }
            }
        }
        @media only screen and (max-width: 440px) {
            width: calc(100% - 40px);
        }
    }
    ::-webkit-scrollbar { 
        display: none; 
    }
    .menu-options.open {
        left: 0px;
    }
    .account-circle {
        position: absolute;
        right: 25px;
        top: 46px;
        cursor: pointer;
        svg {
            color: $third-colour;
            font-size: 40px;
        }
        svg:hover {
            opacity: 0.6;
        }
        .account-menu {
            position: absolute;
            bottom: 0;
            left: -90px;
            padding: 10px;
            background: $second-colour;
            border: 0.5px solid $first-colour;
            transition-duration: 0.6s;
            overflow: hidden;
            transform: scale(0);
            .option {
                text-align: center;
                padding: 10px;
                color: $third-colour;
                cursor: pointer;
            }
            .option:hover {
                color: $first-colour;
                opacity: 0.6;
            }
        }
        .account-menu.show {
            transform: scale(1)
        }
    }
    @media only screen and (max-width: 1430px) {
        .header {
            height: 120px;
            .text {
                top: 30px;
                .site-name {
                    font-size: 30px;
                }
                .slogan {
                    font-size: 20px;
                }
            }
        }
        .burger {
            top: 45px;
        }
        .account-circle {
            top: 40px;
        }
    }
    @media only screen and (max-width: 900px) {
        .header {
            height: 100px;
            .text {
                top: 30px;
                .site-name {
                    font-size: 20px;
                }
                .slogan {
                    font-size: 15px;
                }
            }
        }
        .burger {
            top: 34px;
        }
        .account-circle {
            display: none;
        }
    }
    @media only screen and (max-width: 720px) {
        .header {
            .text {
                width: 75%;
                top: 22px;
                .site-name {
                    font-size: 18px;
                }
                .slogan {
                    font-size: 13px;
                }
            }
        }
    }
    @media only screen and (max-width: 450px) {
        .header {
            .text {
                left: auto;
                top: 26px;
                .site-name {
                    font-size: 15px;
                }
                .slogan {
                    font-size: 10px;
                }
            }
        }
    }
    @media only screen and (max-width: 344px) {
        .header {
            .text {
                top: 17px;
            }
        }
    }
}