@import "../../colours.scss";

.checkbox-container {
    label {
        span {
            font-size: 16px;
        }
    }
    :nth-child(2) {
        color: $font-colour-second;
    }
    .checked {
        svg {
            color: $font-colour-second;
        }
    }
}