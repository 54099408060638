@import "../../colours.scss";

.success-container {
    text-align: center;
    h3 {
        font-weight: 100;
    }
    .icon {
        svg {
            font-size: 80px;
            color: $first-colour;
        }
    }
    .button-container {
        width: 100px;
        margin: auto;
        .button {
            width: 100%;
        }
    }
}