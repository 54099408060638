@import "../../colours.scss";

.radio-button-container {
    margin-top: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    .radio-button {
        width: 100%;
        legend {
            color: black;
            margin-bottom: 6px;
        }
        .radio-item {
            svg {
                color: black;
            }
            :nth-child(2) {
                color: $font-colour-second;
            }
        }
        .radio-item.error {
            svg {
                color: red;
            }
        }
        .radio-item.checked {
            svg {
                color: $font-colour-second;
            }
        }
    }
}