@import "../../colours.scss";

.footer {
    width: calc(100% - 40px);
    padding: 20px;
    background: $second-colour;
    color: $third-colour;
    text-align: center;
    margin-top: 40px;
    position: relative;
    z-index: 1;
    height: 47px;
    p {
        color: $third-colour;
    }
}