@import "../../colours.scss"; 

.dropdown-container {
    width: 100%;
    height: 100%;
    .dropdown {
        width: 100%;
        height: 100%;
        label {
            top: 15px;
            color: black;
        }
        :nth-child(2) {
            position: absolute;
            bottom: -4px;
            width: 100%;
        }
        :nth-child(2)::after {
            border-bottom: $first-colour 2px solid;
        }
        .select {
            div {
                :nth-child(1) {
                    color: $font-colour-second;
                }
            }
        }
    }
    .dropdown.error {
        label {
            color: red;
        }
    }
}